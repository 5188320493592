$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.modalJobsContainer {
  :global {
    .modal.fade {
      opacity: 1;

      .modal-dialog {
        transform: translate(0, 0);
        transition: transform 0.3s ease-out;
      }
    }
  }
}
.modal {
  :global {
    .modal-xl {
      max-width: 1240px;
    }

    .modal-dialog {
      display: flex;
      align-items: center;
      min-height: calc(100% - 1rem);
      max-height: 100%;

      .modal-content {
        @include forceFontFamilyCalendar;
        padding: 0;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12) !important;
        border: 1px solid #ddd;
        border-radius: 10px !important;
        @include forceFontFamilyCalendar;
      }

      .modal-header {
        padding: 20px;
        border-bottom: 0;
        background-color: white;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-radius: 10px !important;
        display: flex;
        align-items: flex-start;
        position: relative;
        width: 100%;
      }

      .modal-title {
        font-size: 1.85rem;
        line-height: 20px;
        font-weight: 600;
        color: $tc_primary_blue;
      }

      .modal-body {
        max-height: 70vh;
        overflow-y: auto;
        padding: 1rem 0 !important;
      }
    }
  }
}

.backdrop:global(.modal-backdrop) {
  background-color: #eef0f5;
  opacity: 0.5;
}

.buttonContainer {
  display: flex;
  align-items: center;
  flex-direction: unset;
  gap: 10px;

  & .checkboxContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    color: #565559;
    background: none;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;

    & .checkIconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border: 2px solid $tc_primary_blue;
      border-radius: 4px;
      color: $tc_primary_blue;

      & .checkIcon {
        font-size: 18px;
        stroke: 2px;
      }
    }
  }
}

.header {
  position: relative;
  align-items: flex-end !important;
}

.headerLeft,
.headerRight {
  display: flex;
  flex-direction: column;
}

.headerLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  flex-grow: 1;
  margin-left: 20px;
  margin-top: 20px;

  & .modalTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    gap: 0.8rem;

    & .modalSubtitleDivider {
      color: #495057 !important;
      font-weight: 400 !important;
    }

    & .modalSubtitle {
      color: #495057 !important;
      font-weight: 500 !important;
      font-size: 1.4rem !important;
      margin-bottom: 0 !important;
    }
  }

  & .headerAddNoteButton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    color: #565559;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: normal;
    background: none;
    border: none;
    padding: 0;
    margin: 0;

    & .headerAddNoteButtonIcon {
      color: $tc_primary_blue;
      font-size: 2.5rem;
    }
  }
}

.headerLeftProvidersView {
  margin-bottom: 20px;
}

.headerRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  margin: 0;
}

.saveButton {
  background-color: $tc_primary_blue !important;
  border-color: $tc_primary_blue !important;
}

.cancelButton {
  border-color: $tc_primary_blue !important;
  color: $tc_primary_blue !important;
  background-color: white !important;
}

.customButton {
  padding: 0.8rem 1.2rem !important;
  border-radius: 0.5rem !important;
  font-size: 1.3rem !important;
  line-height: 1 !important;
  margin-right: 5px !important;
  font-weight: normal !important;
}

.closeIcon {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  cursor: pointer !important;
  color: #c5c3c3;
}
