$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

//ProviderCustomSelect.module.scss

.providerList {
  position: absolute;
  z-index: 1300 !important;
  width: 100%;
  margin-left: -8px;
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.providerList div p {
  font-weight: normal;
  margin: 0;
  padding: 8px 0;
  font-size: 1.5rem;
  color: #5b5a5e;
}

.providerItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  font-size: 1.3rem;
  color: #5b5a5e;
  font-weight: 500;
}

.statusDot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  flex-shrink: 0;
  /* Prevents the dot from shrinking */
}

.selected {
  background-color: #e9e9e9;
}

.categoryHeader {
  background-color: #f0f0f0;
  font-size: 1.2rem !important;
  line-height: 0 !important;
  color: #565559 !important;
  padding: 8px 16px;
}

.providerName * {
  @include forceFontFamilyCalendar;
  color: #565559 !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
}

.MuiInput-underline:before {
  border-bottom: 1px solid #17618e !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: rgb(23 97 142 / 11%) !important;
}

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: rgb(23 97 142 / 11%) !important;
}

.providerName * {
  color: #767578 !important;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  line-height: 1 !important;
}

.formControl * {
  @include forceFontFamilyCalendar;
}

.formControl {
  margin: 0;
  width: 100%;
  color: #57565a;
  padding-right: 2rem !important;

  fieldset {
    border: 1px solid #c0c0c0 !important;
  }
  svg {
    top: unset !important;
  }
}

.defaultSelect {
  @include forceFontFamilyCalendar;
  color: #000000 !important;
  font-size: 1.1rem !important;
  background-color: white !important;
  font-weight: bold !important;
  line-height: 1.5 !important;
}

.availableLabel {
  @include forceFontFamilyCalendar;
  color: #767578 !important;
  font-weight: bold !important;
  font-size: 1.2rem !important;
  line-height: 1.5 !important;
  background-color: white !important;
}

.providerName {
  @include forceFontFamilyCalendar;
  color: #767578 !important;
  font-weight: bold !important;
  font-size: 1.1rem !important;
  line-height: 1 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.statusDot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-weight: lighter;
  color: #a7a7a7;
  font-stretch: semi-expanded;
}
