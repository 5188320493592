$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.listViewContainer {
  background-color: white;
  width: 100%;
  overflow-x: auto;
}

.tableScroll {
  overflow-x: auto;
  height: 100%;
}

.listViewTable {
  border-collapse: separate !important;
  border-spacing: 0px !important;
  min-width: 600px;
  position: relative;
}

.listViewHeader {
  z-index: 8 !important;
  top: 0;
  position: sticky;
  border-right: 1px solid $tc_primary_light_gray;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100vh - 280px);
    box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.15);
    pointer-events: none;
  }
}

.listViewHeader,
.listViewHeaderDate {
  position: sticky;
  top: -1px;
  z-index: 2;
  background-color: #fff;
  max-width: 185px;
  height: 40px;
}

.listViewHeaderDate {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 2;
  background-color: #fff;
  height: 40px;
  text-align: left !important;
  font-weight: 500;
  color: #6c6f79;
  font-size: 14px;
  border: none !important;
  border-bottom: 0.5px solid #dddddd !important;
  width: 14.28%;
  padding: 10px 0 !important;
  background: #f8f8f8;
  text-wrap: nowrap;
  vertical-align: top;

  & .listViewHeaderDateContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;

    & .listViewHeaderDateTitleContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;

      & .listViewHeaderDateParseDate {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        padding: 0 10px;
        text-align: left;
        border: none;
        background: none;

        &:hover {
          text-decoration: underline;
        }
      }

      & .noteButton {
        border-radius: 4px;
        border: 1px solid $tc_primary_blue;
        background-color: transparent;
        color: $tc_primary_blue;
        font-size: 1.2rem;
        font-weight: 500;
        font-style: normal;
        margin-right: 10px;
      }

      & .trackChanges {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 1rem;
        font-weight: 500;
        font-style: normal;
        border: none;
        background: none;
      }
    }

    & .listViewHeaderDateHoliday {
      width: 100%;
      padding: 1px 10px;
      border-radius: 4px;
      color: #565559;
      white-space: normal;
      font-size: 1.1rem;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      background-color: #e6e6e6;
    }

    & .listViewHeaderDateWeekendHoliday {
      background-color: #b5e7fc;
    }
  }
}

.listViewWeekend {
  background-color: #e4f6fe;
}

.stickyHeader {
  top: 102px !important;
}

.stickyHeaderDate {
  top: 102px !important;
}

.listViewCell {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #fff;
  padding-left: 1.8em !important;
  cursor: pointer;
  padding: 0.5rem;
  min-width: 185px;
  max-width: 185px;
  font-weight: 600;
  border: 0.5px solid $tc_primary_light_gray;
  vertical-align: top;
}

.miniListViewCell {
  min-width: 80px;
  max-width: none;
}

.listViewCellJobs {
  text-align: left;
  padding: 0.5rem !important;
  min-width: 185px;
  font-weight: 600;
  height: 40px;
  border: 0.5px solid $tc_primary_light_gray;
  background: white;
  color: #808080;
  vertical-align: middle;
}

.miniListViewCellJobs {
  min-width: 80px;
}

@-moz-document url-prefix() {
  .listViewCellJobs {
    height: auto;
  }
}

.disabledCellJob {
  background-color: #e6e6e6 !important;
  cursor: not-allowed;
}

.listViewSelect {
  width: 100%;
  border: none;
  background-color: white;
  color: $tc_primary_blue;
  padding: 0.5rem;
  appearance: none;
  background-image: url("../../../../../images/cs-assets/cs-dropdown-blue.svg");
  background-repeat: no-repeat;
  background-position: right 1.5rem center;
  background-size: 1.5rem;
  outline: none;
}

.listViewSelect:disabled {
  background-color: #ddd;
}

.listViewTable tr:nth-child(odd) {
  background-color: #ffffff;
}

.listViewTable tr:nth-child(even) {
  background-color: #f8f8f8;
}

.jobCellRectangle {
  width: 1em;
  height: 100%;
  background-color: $tc_primary_light_gray;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.providerCurrentRectangle {
  width: 1em;
  height: 100%;
  background-color: $tc_primary_blue;
}

.providerJobLabel,
.nameJobLabel {
  height: 100%;
  display: flex;
  align-items: center;
}

.providerJobLabel {
  margin-top: 2px;
}

.nameJobLabel {
  padding-left: 1.3em;
}

.providerLabelName {
  display: flex;
  align-items: center;
  flex: 1;
}

.JobContainer {
  padding: 0 1em;
  display: flex;
  align-items: center;
}

.emptyContainer {
  position: relative;
  height: 100%;
  padding: 5px;
  padding-left: 10px;
  display: flex;
  align-items: flex-start;
  background: none;
  border: none;
}

.disabledContainer {
  cursor: not-allowed !important;
}

.providerName {
  color: #979797;
  font-weight: normal;
  padding-left: 1em;
}

.providerCellCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $tc_primary_light_gray;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.assignmentProviderContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  gap: 0.5rem;
}

.assignmentJobsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.listViewTable th:first-child {
  padding: 0.5rem;
  background-color: #fff;
  position: sticky;
  left: 0;
  z-index: 2;
  border-bottom: 0.5px solid #dddddd !important;
}

.listViewTable tbody tr td:first-child {
  padding: 0.5rem;
  background-color: #fff;
}

.headListProvider {
  display: flex;
  align-items: flex-start;
  height: 100%;
  background-color: #fff;

  & .providerWrapper {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
  }
}

.headListJob {
  display: flex;
  align-items: center;
  height: 100%;
  background-color: #fff;
  padding: 5px 0;
}

.customButton {
  padding: 0.8rem 2rem !important;
  border-radius: 0.5rem !important;
  font-size: 1.6rem !important;
  line-height: 1 !important;
  margin-right: 5px !important;
  font-weight: normal !important;
}

.noAssignment {
  color: #c8c5c5;
  font-size: 1rem;
  margin-bottom: 0 !important;
  line-height: 1.6rem;
  font-weight: normal;
  display: flex;
  justify-items: center;
}

.calendarLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: white;
  padding: 1em;
  flex-direction: column;
  font-weight: lighter;
  font-size: 1.2em;
  color: #a7a7a7;
  font-stretch: semi-expanded;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: $tc_primary_blue;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.unassignedContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  padding-left: 10px;
  gap: 2px;
  border: 0.5px solid #dadada;
  border-radius: 4px !important;
  font-weight: bold;
  background: #fff;

  .noAssignment {
    font-weight: 600;
    font-size: 1.2rem;
    color: #565559;
    line-height: normal;
    text-transform: capitalize;
  }
}

.unassignedFadedContainer {
  opacity: 0.3;
}

.understaffedCounter,
.jobUnderstaffedCounter {
  position: absolute;
  top: 5px;
  right: 5px;
  color: $dark-red;
  line-height: 1.2rem;
  font-weight: 600;
  background-color: #dc354533;
  border-radius: 100px;
  padding: 3px 8px;
}

.jobUnderstaffedCounter {
  top: 10px;
  right: 10px;
}

.todayOutlined {
  border: 2px solid #18618e !important;
}
