$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 260px);
  gap: 12px;
  .rulesContainer {
    & .draftListContainer {
      list-style: none;
      padding: 0 1em;
      border: 1px solid #e0e0e0;
      border-radius: 0.5em;

      & .draftListItem {
        border: none;
        margin: 0;
        color: #57565a;
        padding: 1rem;
        background: white;
        border-radius: 0.5em;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
      }
    }

    & .heading {
      font-size: 1.2rem;
      color: #57565a;
      font-weight: 500;
      text-align: center;
      margin-top: 1rem;
      padding: auto;
      letter-spacing: 0.4px;
    }

    & .ruleTable {
      background: #f4f4f4;
      padding: 1rem;
      border-radius: 0.8rem;
      margin: 20px 0;
      border: 1px solid #e0e0e0;
      max-height: calc(100vh - 440px);

      overflow: auto;

      .listContainer {
        list-style: none;
        padding: 0 1em;

        .listItem {
          color: #57565a;
          padding: 1rem;
          background: white;
          margin: 10px 0;
          border-radius: 0.5em;
          border: 1px solid #e0e0e0;
          font-weight: 500;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .container {
    height: calc(100vh - 460px);

    .rulesContainer {
      max-height: calc(100vh - 350px);

      & .ruleTable {
        max-height: calc(100vh - 700px);
      }
    }
  }
}

@media (min-width: 942px) {
  .container {
    height: calc(100vh - 260px);
    .rulesContainer {
      & .ruleTable {
        max-height: calc(100vh - 620px);
      }
    }
  }
}

.ruleItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hiddenCheckbox {
  opacity: 0;
  position: absolute;
}

.checkboxLabel {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  position: relative;
  border: 2px solid #57565a;
  padding: 0;
  transition: background-color 0.2s;
}

.hiddenCheckbox:checked + .checkboxLabel {
  background-color: $tc_primary_blue;
  border-color: $tc_primary_blue;
}

.checkIcon {
  color: white;
  font-size: 18px;
  display: none;
}

.hiddenCheckbox:checked + .checkboxLabel .checkIcon {
  display: block;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
}

.customCheckbox {
  height: 22px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  background-color: white;
  padding-top: 10px;
  z-index: 99;
}

.cancelButton,
.applyButton {
  padding: 10px 20px !important;
  border-radius: 5px !important;
  cursor: pointer;
  z-index: 99;
}

.cancelButton {
  font-weight: 600;
  background-color: white;
  color: $tc_primary_blue;
  border: 1px solid $tc_primary_blue;
}

.applyButton {
  background-color: $tc_primary_blue;
  color: white;
  font-weight: 600;
  border: none;
  z-index: 99;
}

.applyButton:disabled {
  opacity: 0.5;
}

.selectWrapper {
  & .preferencesSelect {
    border: 1px solid $tc_primary_blue !important;
    border-radius: 0.8rem;
    outline-color: $tc_primary_blue !important;
    padding: 0.5rem 1rem;
    padding-right: 3rem;
    appearance: none;
    color: $tc_primary_blue;
    background-image: url("../../../../../../images/cs-assets/cs-dropdown-blue.svg");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1.5rem;
    outline: none;
    cursor: pointer;
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 300px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: $tc_primary_blue;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
