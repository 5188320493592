$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

//CalendarGridView.module.scss

.calendarContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
}

.otherMonthDay {
  background-color: #edf0f4;
}

.backgroundDay {
  background-color: white;
  border: 1px solid #dddddd !important;
  width: 14.28%;
}

.headWeekday {
  text-align: left !important;
  font-weight: 500;
  color: #6c6f79;
  font-size: 14px;
  border: none !important;
  background-color: white;
  padding: 10px !important;
  width: 14.28%;
  position: -webkit-sticky;
  position: sticky;
  margin-top: 175px !important;
  top: 130px !important;
  background: #f8f8f8;
}

.headWeekend {
  background-color: #e4f6fe !important;
}

.headDay {
  text-align: left !important;
  font-weight: 600;
  color: #6c6f79;
  font-size: 14px;
  border: none !important;
  background-color: white;
  border-top: 1px solid #dddddd !important;
  border-bottom: 1px solid #dddddd !important;
}

.currentDate {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
  gap: 5px;
  font-weight: 600;
  color: #68676b;

  & .holidaysContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    color: #565559;
  }
}

.headerContainer {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;

  table {
    margin-bottom: 0 !important;
  }
}

.daysContainer {
  flex-grow: 1;
  background-color: white;

  .tableContainer {
    margin-bottom: 0;
  }
}

.customButton {
  padding: 0.8rem 2rem !important;
  border-radius: 0.5rem !important;
  font-size: 1.6rem !important;
  line-height: 1 !important;
  margin-right: 5px !important;
  font-weight: normal !important;
}

.addIconColor {
  color: $tc_primary_light_gray;
  font-weight: bolder;
  font-size: 2rem !important;
  cursor: pointer;
}

.assignmentDetail {
  display: flex;
  font-size: 1rem;
}

.jobAbbrev {
  font-weight: 600;
  color: #57565a;
  margin-bottom: 6px;
  font-size: 1.25rem;
  font-stretch: semi-expanded;
  cursor: pointer;
}

.grayDot {
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: $tc_primary_light_gray;
  margin-right: 2px;
  vertical-align: middle;
}

.pinkDot {
  background-color: #e279b7;
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-right: 2px;
  vertical-align: middle;
}

.blueDot {
  background-color: #6b86e6;
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-right: 2px;
  vertical-align: middle;
}
.providerName {
  color: #808080;
  font-size: 1.25rem;
  font-stretch: semi-expanded;
  margin-bottom: 6px;
  cursor: pointer;
}

.saveButton {
  background: $tc_primary_blue !important;
}

.dayGridHeaderWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-bottom: 5px;

  & .dayGridHeaderContent {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    & .dayGridHeaderDate {
      text-wrap: nowrap;
    }

    & .dayGridHeaderHolidaysWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      color: #565559;
    }
  }

  & .dayGridHeaderRightContainer {
    display: flex;
    gap: 4px;

    & .notesIconButton {
      padding: 2px;
      color: #dadada;
    }

    & .noteButton {
      border-radius: 4px;
      border: 1px solid $tc_primary_blue;
      background-color: transparent;
      color: $tc_primary_blue;
      font-size: 1.2rem;
      font-weight: 500;
      font-style: normal;
    }

    & .trackChanges {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 1rem;
      font-weight: 500;
      font-style: normal;
      border: none;
      background: none;
    }
  }

  & .dayGridHeaderAddContainer {
    display: flex;
    justify-content: flex-end;
    color: $tc_primary_light_gray;
    cursor: pointer;
  }
}

.assignmentsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
}

.todayOutlined {
  border: 2px solid #18618e !important;
}
